import { getApiClient } from '@src/types/api-client'
import { ExceptionLog } from '@src/types/ExceptionLog'
import { ExceptionsFiltersDto } from '@src/types/ExceptionsFiltersDto'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { MutationFunction, QueryFunctionContext, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

const SCOPE = 'exceptions'
const LIST = 'list'
const DETAIL = 'details'
const APP_NAMES_SCOPE = 'applicationNames'

const TEN_MINUTES = 600000

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  list: (filters: ExceptionsFiltersDto) => [{ scope: SCOPE, entity: LIST, ...filters }] as const,
  allLists: () => [{ scope: SCOPE, entity: LIST }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
  applicationNames: () => [{ scope: APP_NAMES_SCOPE, entity: LIST }] as const,
}

export interface UpdateDevopsTicketDto {
  id: string
  devopsTicketNumber: string
  status: string
}

function buildApplicationNames(applicationNames: string[]): SelectValueListItem[] {
  const valueList: SelectValueListItem[] = []
  if (applicationNames) {
    applicationNames.forEach((e) => valueList.push({ label: e, value: e }))
    valueList.sort((one, two) => (one.value < two.value ? -1 : 1)) // sort asc
  }
  return valueList
}

const getExceptionsList = async ({
  queryKey: [filters],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['list']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.post('ExceptionLogs/Search', filters)
  return response.data as ExceptionLog[]
}

export function useExceptionsList(filter: ExceptionsFiltersDto): [ExceptionLog[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: [...keysFactory.list(filter)],
    queryFn: getExceptionsList,
    placeholderData: [],
    staleTime: TEN_MINUTES,
    gcTime: TEN_MINUTES,
    refetchOnWindowFocus: 'always',
  })

  return [data, isFetching]
}

const updateDevopsTicket: MutationFunction<ExceptionLog, { id: string; dto: UpdateDevopsTicketDto }> = async ({
  id,
  dto,
}) => {
  const apiClient = getApiClient()
  const response = await apiClient.put(`ExceptionLogs/${encodeURIComponent(id)}/DevopsTicket`, dto)
  return response.data as ExceptionLog
}

export function useUpdateDevopsTicket(): [
  MutationFunction<ExceptionLog, { id: string; dto: UpdateDevopsTicketDto }>,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isError, reset } = useMutation({
    mutationFn: updateDevopsTicket,
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: keysFactory.allLists() })
      return data
    },
  })

  return [mutateAsync, isPending, isError, reset]
}

const getApplicationNames = async () => {
  const apiClient = getApiClient()
  const response = await apiClient.get('ExceptionLogs/ApplicationNames')
  return response.data as string[]
}

export function useApplicationNames(): [SelectValueListItem[], boolean] {
  const { isFetching, data } = useQuery({
    queryKey: keysFactory.applicationNames(),
    queryFn: getApplicationNames,
  })

  const list = useMemo(() => {
    return buildApplicationNames(data)
  }, [data])

  return [list, isFetching]
}

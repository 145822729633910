import { Alert, AlertTitle, Snackbar } from '@mui/material'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

type props = {
  error: Error
  resetError: (error: null) => void
}

export function ErrorFallback({ error, resetError }: props): JSX.Element {
  const { reset } = useQueryErrorResetBoundary()
  const { t } = useTranslation()

  const handleClose = () => {
    resetError(null)
    reset()
  }

  return (
    <Snackbar
      open
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ width: '100%' }}
      style={{ top: 0 }}
    >
      <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>
        <AlertTitle>{t('common.error')}</AlertTitle>
        {t(`common.errors.${error.message}`)}
      </Alert>
    </Snackbar>
  )
}

import { Box, Button, Card, CardContent, CardMedia, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import LanguagePicker from '../App/components/LanguagePicker'
import { loginRequest } from '../../authConfig'

export default function LoginPage() {
  const { t } = useTranslation()
  document.title = t('common.login')

  const { instance } = useMsal()

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Card>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LanguagePicker />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CardMedia sx={{ width: '200px' }} component="img" image="/images/iceberg-logo.png" alt="Iceberg Finance" />
        </div>
        <CardContent>
          <Stack spacing={3} sx={{ paddingLeft: '100px', paddingRight: '100px', paddingTop: '20px' }}>
            <Button type="button" onClick={handleLogin}>
              {t('common.login')}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  )
}

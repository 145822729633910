import {
  getNumberFromQueryString,
  getStringArrayFromQueryString,
  getStringFromQueryString,
} from '@src/services/http-common'
import { useMemo } from 'react'
import { getStartDate } from './browseExceptions-selectors'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import { formatDateTime } from '../App/utils/Formatter'
import { IconButton, Link } from '@mui/material'
import { Enum } from '@src/types/Constants'
import LinkIcon from '@mui/icons-material/Link'
import EditIcon from '@mui/icons-material/Edit'

export function useExceptionsColumns(handleRowClick) {
  const { t } = useTranslation()

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        flex: 0.25,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'applicationName',
        headerName: t('exceptionsColumns.applicationName'),
        description: t('exceptionsColumns.applicationName'),
        flex: 1.5,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'operationName',
        headerName: t('exceptionsColumns.operationName'),
        description: t('exceptionsColumns.operationName'),
        flex: 1.75,
        headerAlign: 'left',
        align: 'left',
      },
      {
        field: 'message',
        headerName: t('exceptionsColumns.message'),
        description: t('exceptionsColumns.message'),
        flex: 4,
        headerAlign: 'left',
        align: 'left',
        display: 'flex',
      },
      {
        field: 'count',
        headerName: t('exceptionsColumns.count'),
        description: t('exceptionsColumns.count'),
        flex: 0.5,
        headerAlign: 'left',
        align: 'left',
        display: 'flex',
      },
      {
        field: 'firstTimeGenerated',
        headerName: t('exceptionsColumns.date'),
        description: t('exceptionsColumns.date'),
        flex: 1.75,
        headerAlign: 'left',
        align: 'left',
        display: 'flex',
        alignItems: 'center',
        renderCell: (params) => (
          <div style={{ textAlign: 'left' }}>
            {params.row.lastDetectedOn && (
              <p style={{ margin: '0', fontSize: 'smaller' }}>
                {t('exceptionsColumns.lastDetectedOn')}: {formatDateTime(params.row.lastDetectedOn)}
              </p>
            )}
            {params.row.firstTimeGenerated && (
              <p style={{ margin: '0', fontSize: 'smaller', color: 'darkGray' }}>
                {t('exceptionsColumns.createdOn')}: {formatDateTime(params.row.firstTimeGenerated)}
              </p>
            )}
          </div>
        ),
      },
      {
        field: 'devopsTicketNumber',
        headerName: t('exceptionsColumns.actions'),
        description: t('exceptionsColumns.actions'),
        flex: 0.75,
        headerAlign: 'center',
        align: 'center',
        display: 'flex',
        editable: true,
        renderCell: (params) => {
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <IconButton onClick={() => handleRowClick(params)}>
                  <EditIcon />
                </IconButton>
                {params.row.hyperlink && (
                  <Link href={params.row.hyperlink} target="_blank">
                    <LinkIcon style={{ marginTop: 8 }} />
                  </Link>
                )}
              </div>
            </>
          )
        },
      },
    ],
    [handleRowClick, t],
  )
  return columns
}

export function useSearchQuery(searchParams: URLSearchParams) {
  const query = useMemo(() => {
    return {
      applicationName: getStringFromQueryString(searchParams, 'applicationName', ''),
      startDate: getStringFromQueryString(searchParams, 'startDate', getStartDate()),
      statuses: getStringArrayFromQueryString(searchParams, 'statuses', [
        Enum.ExceptionStatusEnum.New,
        Enum.ExceptionStatusEnum.TicketAssigned,
      ]),
      limit: getNumberFromQueryString(searchParams, 'limit', 25),
      offset: getNumberFromQueryString(searchParams, 'offset', 0),
    }
  }, [searchParams])
  return query
}

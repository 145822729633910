import React from 'react'
import { forwardRef, useMemo, useState } from 'react'
import { BrowserRouter as Router, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { Container, CssBaseline, LinkProps, ThemeProvider, createTheme } from '@mui/material'
import { blue } from '@mui/material/colors'
import AppHeader from './components/AppHeader'
import ColorModeContext from '../../contexts/appContext '
import LoginPage from '../Authentication/LoginPage'
import TopLevelRoutes from './Routes'
import { interceptError } from '@src/services/http-common'
import { AxiosError } from 'axios'
import { ErrorFallback } from '@src/components/ErrorFallback'
import AlertBanner from './components/AlertBanner'
import { getUserToken, isExpiredToken, loadUserFromJwtString } from '../../data/providers/UserProvider'

// eslint-disable-next-line react/display-name
const LinkBehavior = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  },
)

export default function App() {
  const [error, setError] = React.useState<AxiosError | Error | null>(null)

  const PREFERS_DARK_SETTING = 'darkMode'
  const prevMode = localStorage.getItem(PREFERS_DARK_SETTING)
  const [mode, setMode] = useState<'light' | 'dark'>((prevMode as 'light' | 'dark') || 'light')
  const { pathname } = window.location
  const isAuthenticate = pathname === '/auth-page'
  const jwtToken = getUserToken()
  const user = jwtToken ? loadUserFromJwtString(jwtToken) : null
  const needAuth = !user || isExpiredToken(user.exp)
  const isIcebergGrDevs = user?.roles.includes('IcebergGrDevs') ?? false

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const mode = prevMode === 'light' ? 'dark' : 'light'
          localStorage.setItem(PREFERS_DARK_SETTING, mode)
          return mode
        })
      },
      error: error,
    }),
    [error],
  )

  interceptError(setError)

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiLink: {
            defaultProps: {
              component: LinkBehavior,
            } as LinkProps,
          },
          MuiButtonBase: {
            defaultProps: {
              LinkComponent: LinkBehavior,
            },
          },
          MuiInputBase: {
            defaultProps: {
              // Needed to prevent adding a global style for every input field
              disableInjectingGlobalStyles: true,
            },
          },
        },
        palette: {
          primary: { main: mode === 'dark' ? blue[200] : blue[400] },
          background: {
            default: mode === 'dark' ? '#1e1e1e' : '#ffffff',
          },
          mode: mode,
        },
      }),
    [mode],
  )
  return (
    <>
      <ThemeProvider theme={theme}>
        <ColorModeContext.Provider value={colorMode}>
          {error && <ErrorFallback error={error} resetError={setError} />}
          <CssBaseline />
          <Router>
            {needAuth && !isAuthenticate && <LoginPage />}
            {!needAuth && (
              <>
                <AlertBanner message="Production" show={isIcebergGrDevs} />
                <AppHeader hasAlertBanner={isIcebergGrDevs} />
                <Container maxWidth="xl" disableGutters>
                  <TopLevelRoutes />
                </Container>
              </>
            )}
          </Router>
        </ColorModeContext.Provider>
      </ThemeProvider>
    </>
  )
}

import * as React from 'react'
import { AppBar, Toolbar, IconButton, useTheme, Typography, Container } from '@mui/material'
// eslint-disable-next-line import/named
import { SvgIconComponent } from '@mui/icons-material'
import WarningIcon from '@mui/icons-material/Warning'

type AlertBannerProps = {
  message: string
  variant?: 'error' | 'info' | 'warning' | 'success'
  show?: boolean
  icon?: React.ReactElement<SvgIconComponent>
}

function AlertBanner({ message, variant, show, icon }: AlertBannerProps) {
  const theme = useTheme()

  const barColor = variant === 'error' ? theme.palette.error.main : theme.palette.info.main

  return (
    <AppBar position="fixed" sx={{ backgroundColor: barColor, top: 0, display: show ? 'flex' : 'none', zIndex: 1500 }}>
      <Container maxWidth="xl" disableGutters>
        <Toolbar variant="dense" style={{ justifyContent: 'center', display: 'flex' }}>
          <IconButton color="inherit" aria-label="menu" sx={{ marginRight: 1 }}>
            {icon}
          </IconButton>
          <Typography variant="h5" color="inherit">
            {message}
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

AlertBanner.default = {
  variant: 'error',
  show: true,
  icon: <WarningIcon />,
}

export default AlertBanner

import { getUserToken } from '@src/data/providers/UserProvider'
import axios, { AxiosError } from 'axios'

const jwtToken = getUserToken()
let instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${jwtToken}` },
})

export function initApiClient(reportApiError: (error: AxiosError) => void) {
  const jwtToken = getUserToken()
  instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { Authorization: `Bearer ${jwtToken}` },
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      if (reportApiError) reportApiError(error)
      return error
    },
  )
}

export function getApiClient() {
  return instance
}

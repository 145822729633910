import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { JwtToken } from '@src/data/api/user-api/user-api'
import { getUserToken } from '@src/data/providers/UserProvider'

export default function AuthPage(): JSX.Element {
  const navigate = useNavigate()
  const token: JwtToken = { jwt: getUserToken() }
  useEffect(() => {
    navigate('/')
  }, [navigate, token.jwt])

  return <div />
}

import { Button, ButtonGroup, debounce, InputAdornment, TextField, Toolbar } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {
  FilterAltOutlined as FilterAltOutlinedIcon,
  FilterAltOffOutlined as FilterAltOffOutlinedIcon,
} from '@mui/icons-material'

type props = {
  handleSearch: (value: string) => void
  onOpenFilterClicked: () => void
  hasFilter: boolean
  handleClearFilter: () => void
}

function Header({ handleSearch, onOpenFilterClicked, hasFilter, handleClearFilter }: props) {
  const debounceOnChange = debounce(handleSearch, 1000)

  return (
    <Toolbar disableGutters>
      <TextField
        sx={{ minWidth: '20%', borderRadius: '10px' }}
        onChange={(e) => debounceOnChange(e.target.value)}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          sx: { borderRadius: '1rem' },
        }}
      />
      <ButtonGroup variant="text" disableElevation>
        <Button size="large" onClick={onOpenFilterClicked}>
          <FilterAltOutlinedIcon fontSize="large" color={!hasFilter ? 'inherit' : 'warning'} />
        </Button>
        <Button size="large" onClick={handleClearFilter} disabled={!hasFilter}>
          <FilterAltOffOutlinedIcon fontSize="medium" />
        </Button>
      </ButtonGroup>
    </Toolbar>
  )
}
export default Header

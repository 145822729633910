import { reportErrorToConsole } from '@src/services/error-logger'
import { apiClient } from '@src/services/http-common'

export type JwtToken = {
  jwt: string
}

export async function signIn(data: JwtToken): Promise<JwtToken> {
  return await apiClient
    .post('/GenerateJwtToken', data)
    .then((response) => {
      return response.data as JwtToken
    })
    .catch((error) => {
      reportErrorToConsole(error)
      return {} as JwtToken
    })
}

import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, TextField } from '@mui/material'
import { UpdateDevopsTicketDto } from '@src/data/api/exceptions-api/exceptions-api'
import { memo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Done as DoneIcon } from '@mui/icons-material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import SelectComponent from '@src/components/SelectComponent'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import LinkIcon from '@mui/icons-material/Link'
import { Enum } from '@src/types/Constants'

type props = {
  dto: UpdateDevopsTicketDto
  exceptionStatuses: SelectValueListItem[]
  open: boolean
  onApply: (dto: UpdateDevopsTicketDto) => void
  onCancel: () => void
}

function ExceptionsStatusDialog({ dto, exceptionStatuses, open, onApply, onCancel }: props) {
  const { t } = useTranslation()
  const DevopsBaseUrl = 'https://dev.azure.com/IcebergFinance/IcebergFinance/_workitems/edit/'
  const { register, getValues, handleSubmit, reset } = useForm<UpdateDevopsTicketDto>({
    defaultValues: dto,
  })

  useEffect(() => {
    reset(dto)
  }, [dto, open, reset])

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {t('exceptionsColumns.devOpsTicket')}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onApply)}>
        <DialogContent>
          <Grid item container direction="column" spacing={2} sx={{ mt: 5, width: 600 }}>
            <Grid item>
              <TextField
                id={dto.id}
                focused
                required={getValues('status') != Enum.ExceptionStatusEnum.Ignored}
                placeholder={t('exceptionsColumns.devOpsTicket')}
                {...register('devopsTicketNumber')}
                label={t('exceptionsColumns.devOpsTicket')}
                type="number"
                inputProps={{ maxLength: 5 }}
              />
              {getValues('devopsTicketNumber') && (
                <Link href={DevopsBaseUrl + getValues('devopsTicketNumber')} target="_blank">
                  <LinkIcon style={{ marginTop: 8 }} fontSize="large" />
                </Link>
              )}
            </Grid>
            <Grid item>
              <SelectComponent
                required
                valueList={exceptionStatuses}
                label={t('common.status')}
                {...register('status')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AsyncActionButton variant="contained" type="submit" startIcon={<DoneIcon />}>
            {t('common.apply')}
          </AsyncActionButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default memo(ExceptionsStatusDialog)

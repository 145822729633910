import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function App(): JSX.Element {
  const { t, i18n } = useTranslation()
  const targetLanguage = t('common.targetLanguage')

  const changeLanguage = () => {
    void (async () => {
      await i18n.changeLanguage(targetLanguage)
    })()
  }

  return <Button onClick={changeLanguage}>{targetLanguage}</Button>
}

import {
  UpdateDevopsTicketDto,
  useApplicationNames,
  useExceptionsList,
  useUpdateDevopsTicket,
} from '@src/data/api/exceptions-api/exceptions-api'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid'
import { getNumberFromQueryString } from '@src/services/http-common'
import { Box, Paper, Stack, TableContainer, useTheme } from '@mui/material'
import { frFR, enUS } from '@mui/x-data-grid/locales'
import Footer from './Components/footer'
import { useExceptionsColumns, useSearchQuery } from './browseExceptions-hooks'
import Header from './Components/header'
import { getStartDate } from './browseExceptions-selectors'
import { ExceptionsFiltersDto } from '@src/types/ExceptionsFiltersDto'
import { useCallback, useState } from 'react'
import ExceptionsFiltersDialog from './Components/ExceptionsFiltersDialog'
import { Enum } from '@src/types/Constants'
import ExceptionStatusDialog from './Components/ExceptionStatusDialog'
import { useExceptionStatuses } from './Components/ExceptionStatusDialog-hooks'

export default function BrowseExceptionsPage() {
  const { i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const updateSearch = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const theme = useTheme()

  const query = useSearchQuery(searchParams)

  const [exceptions, isFetching] = useExceptionsList(query)

  const [applicationNames, isFetchingAppNames] = useApplicationNames()

  // update search
  const [openFilter, setOpenFilter] = useState(false)

  const updateUriQuery = () => {
    const newUrl = `${location.pathname}?${updateSearch.toString()}`
    navigate(newUrl, { replace: false })
  }

  const setPagination = (pagination: GridPaginationModel) => {
    updateSearch.set('offset', encodeURIComponent(query.limit * pagination.page))
    updateSearch.set('limit', encodeURIComponent(pagination.pageSize))
    updateUriQuery()
  }

  const pagination = {
    pageSize: getNumberFromQueryString(searchParams, 'limit', 25),
    page: getNumberFromQueryString(searchParams, 'offset', 0) / getNumberFromQueryString(searchParams, 'limit', 25),
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)

  const [updateDevopsTicket] = useUpdateDevopsTicket()
  const exceptionStatuses = useExceptionStatuses()
  const handlePostTicket = useCallback(
    async (dto: UpdateDevopsTicketDto) => {
      const id = dto.id
      await updateDevopsTicket({ id, dto })
      setOpenDialog(false)
      setSelectedRow(null)
    },
    [updateDevopsTicket],
  )

  const handleOpenDialog = useCallback((params) => {
    setSelectedRow(params.row)
    setOpenDialog(true)
  }, [])

  const handleSearch = (value: string) => {
    updateSearch.set('searchValue', encodeURIComponent(value))
    updateUriQuery()
  }

  const handleFilters = (filters: ExceptionsFiltersDto) => {
    updateSearch.set('applicationName', encodeURIComponent(filters.applicationName))
    updateSearch.set('statuses', encodeURIComponent(filters.statuses.join(',')))
    updateSearch.set('startDate', encodeURIComponent(filters.startDate))

    updateUriQuery()
    setOpenFilter(false)
  }

  const handleClearFilter = () => {
    handleFilters({
      applicationName: '',
      startDate: getStartDate(),
      statuses: [Enum.ExceptionStatusEnum.New, Enum.ExceptionStatusEnum.TicketAssigned],
    } as ExceptionsFiltersDto)
  }

  const hasFilter = (): boolean => {
    return query.applicationName !== '' || query.startDate !== getStartDate()
  }

  // Table def
  const columns: GridColDef[] = useExceptionsColumns(handleOpenDialog)

  const localeTextDatagrid =
    i18n.language === 'fr'
      ? frFR.components.MuiDataGrid.defaultProps.localeText
      : enUS.components.MuiDataGrid.defaultProps.localeText

  return (
    <>
      <TableContainer component={Paper} sx={{ mt: '3rem', mb: '1rem', borderRadius: '8px' }}>
        <Stack sx={{ mx: '15px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <Header
              handleSearch={handleSearch}
              onOpenFilterClicked={() => setOpenFilter(true)}
              handleClearFilter={handleClearFilter}
              hasFilter={hasFilter()}
            />
          </Box>
        </Stack>

        <DataGrid
          sx={{
            height: '47rem',
            marginTop: '1rem',
            '.MuiDataGrid-columnHeaders div:first-of-type': {
              bgcolor: '#000059',
              color: 'white',
              fontWeight: 'bold',
            },
            '.MuiDataGrid-cell': {
              color: theme.palette.mode === 'dark' ? 'white' : '#14233A',
              fontWeight: 'bold',
            },
            '.MuiDataGrid-sortIcon': { color: 'white' },
          }}
          rows={exceptions}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: query.limit,
              },
            },
            columns: {
              columnVisibilityModel: { id: false },
            },
          }}
          onPaginationModelChange={(model: GridPaginationModel) => setPagination(model)}
          onCellClick={(params, event) => {
            if (open) {
              event.defaultMuiPrevented = true
            }
          }}
          pageSizeOptions={[25, 50, 100]}
          paginationMode="server"
          rowCount={exceptions?.length}
          loading={isFetching}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          localeText={localeTextDatagrid}
          slots={{
            footer: () => (
              <Footer
                disabledPreview={query.offset === 0}
                disabledNext={exceptions?.length < query.limit}
                pagination={pagination}
                setPagination={setPagination}
              />
            ),
          }}
        />
      </TableContainer>
      <ExceptionsFiltersDialog
        open={openFilter}
        applicationNames={applicationNames}
        isFetching={isFetchingAppNames}
        filterValues={query}
        onApplyFilters={handleFilters}
        onCancel={() => setOpenFilter(false)}
        handleClearFilter={handleClearFilter}
      />
      <ExceptionStatusDialog
        dto={
          {
            id: selectedRow?.id,
            status: selectedRow?.status,
            devopsTicketNumber: selectedRow?.devopsTicketNumber,
          } as UpdateDevopsTicketDto
        }
        exceptionStatuses={exceptionStatuses}
        open={openDialog}
        onApply={handlePostTicket}
        onCancel={() => {
          setOpenDialog(false)
          setSelectedRow(null)
        }}
      />
    </>
  )
}

import * as React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function AppMenu(): JSX.Element {
  const { t } = useTranslation()
  const [anchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const navigate = useNavigate()
  return (
    <div>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={() => navigate('/', { replace: true })}
      >
        {t('common.errorsTrackerTool')}
      </Button>
    </div>
  )
}

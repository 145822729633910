import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from '../Authentication/LoginPage'
import AuthPage from '../Authentication'
import BrowseExceptionsPage from '../BrowseExceptionsPage'

export default function TopLevelRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/exceptions/browse" replace />} />
      <Route path="/exceptions/browse" element={<BrowseExceptionsPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="auth-page" element={<AuthPage />} />
    </Routes>
  )
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material'
import { ExceptionsFiltersDto } from '@src/types/ExceptionsFiltersDto'
import { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { SimpleDatePicker } from '@src/components/DatePicker'
import SelectComponent from '@src/components/SelectComponent'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import PageSpinner from '@src/components/PageSpinner'
import { Enum } from '@src/types/Constants'

type props = {
  filterValues: ExceptionsFiltersDto
  open: boolean
  applicationNames: SelectValueListItem[]
  isFetching: boolean
  onApplyFilters: (filters: ExceptionsFiltersDto) => void
  onCancel: () => void
  handleClearFilter: () => void
}

function ExceptionsFiltersDialog({
  filterValues,
  open,
  onApplyFilters,
  applicationNames,
  isFetching,
  onCancel,
  handleClearFilter,
}: props) {
  const { t } = useTranslation()

  const { register, handleSubmit, reset, setValue, control } = useForm<ExceptionsFiltersDto>({
    defaultValues: filterValues,
  })

  const [statuses, setStatuses] = useState<string[]>(filterValues.statuses)

  useEffect(() => {
    if (!open) {
      reset(filterValues)
    }
  }, [open, filterValues, reset])

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setStatuses(value as string[])
    setValue('statuses', value as string[])
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        {t('common.filters')}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <form onSubmit={handleSubmit(onApplyFilters)}>
        <DialogContent>
          <PageSpinner isLoading={isFetching} />
          <Grid item container direction="column" spacing={2} sx={{ mt: 5, width: 600 }}>
            <Grid item>
              <SelectComponent
                valueList={applicationNames}
                label={t('common.applicationName')}
                {...register('applicationName')}
              />
            </Grid>
            <Grid item>
              <TextField
                label={t('common.status')}
                value={statuses}
                select
                SelectProps={{ multiple: true, onChange: handleChange }}
                fullWidth
              >
                <MenuItem value={Enum.ExceptionStatusEnum.New}>{t('exceptionStatus.new')}</MenuItem>
                <MenuItem value={Enum.ExceptionStatusEnum.TicketAssigned}>
                  {t('exceptionStatus.ticketAssigned')}
                </MenuItem>
                <MenuItem value={Enum.ExceptionStatusEnum.Closed}>{t('exceptionStatus.closed')}</MenuItem>
                <MenuItem value={Enum.ExceptionStatusEnum.Ignored}>{t('exceptionStatus.ignored')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item justifyContent="center">
              <Stack justifyContent="space-between" direction="row">
                <SimpleDatePicker
                  label={t('common.startDate')}
                  sx={{ minWidth: '49%' }}
                  name="startDate"
                  control={control}
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <AsyncActionButton startIcon={<CancelIcon />} onClick={handleClearFilter}>
            {t('common.reset')}
          </AsyncActionButton>
          <AsyncActionButton variant="contained" type="submit" startIcon={<DoneIcon />}>
            {t('common.apply')}
          </AsyncActionButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default memo(ExceptionsFiltersDialog)

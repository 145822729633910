import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let ai: ApplicationInsights | null = null

export function initializeAppInsights(): ApplicationInsights {
  ai = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_APP_INSIGHT_INSTRUMENTAL_KEY,
    },
  })

  ai.loadAppInsights()
  return ai
}

import { Enum } from '@src/types/Constants'
import { SelectValueListItem } from '@src/types/SelectValueListItem'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export function useExceptionStatuses(): SelectValueListItem[] {
  const { t } = useTranslation()

  return useMemo(
    () => [
      { label: t('exceptionStatus.ticketAssigned'), value: Enum.ExceptionStatusEnum.TicketAssigned },
      { label: t('exceptionStatus.closed'), value: Enum.ExceptionStatusEnum.Closed },
      { label: t('exceptionStatus.ignored'), value: Enum.ExceptionStatusEnum.Ignored },
    ],
    [t],
  )
}

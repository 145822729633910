import React from 'react'
import { IconButton, Typography } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { GridFooter, GridFooterContainer, GridPaginationModel } from '@mui/x-data-grid'

type props = {
  pagination: GridPaginationModel
  setPagination: (pagination: GridPaginationModel) => void
  disabledPreview: boolean
  disabledNext: boolean
}

function Footer({ pagination, setPagination, disabledPreview, disabledNext }: props) {
  return (
    <GridFooterContainer>
      <GridFooter sx={{ '& .MuiTablePagination-actions, & .MuiTablePagination-displayedRows': { display: 'none' } }} />
      <div style={{ display: 'flex' }}>
        <IconButton
          onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })}
          disabled={disabledPreview}
          sx={{
            border: `1px solid ${disabledPreview ? '#eeeeee' : '#c4c4c4'}`,
            width: '2rem',
            height: '2rem',
            mr: '10px',
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography
          sx={{
            border: '1px solid #c4c4c4',
            borderRadius: `20px`,
            width: '2rem',
            height: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexWrap: 'wrap',
            mr: '10px',
          }}
        >
          {pagination.page + 1}
        </Typography>
        <IconButton
          onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })}
          disabled={disabledNext}
          sx={{
            border: `1px solid ${disabledNext ? '#eeeeee' : '#c4c4c4'}`,
            width: '2rem',
            height: '2rem',
            mr: '10px',
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
    </GridFooterContainer>
  )
}

export default Footer
